import React from 'react';
import SpotBesch from "./SpotBesch";
import { FaRocket } from 'react-icons/fa';

const SpotBeschreibung = (props) => {
    const styleList = {
        color: 'darkblue',
        padding: '0.5rem',
        fontSize: '0.8rem',
        textAlign: 'left',
    };
    const styleList2 = {
        color: 'rgba(6,6,6,0.77)',
        padding: '0rem',
        fontSize: '1.5rem',
        marginRight: '0.5rem',
        textAlign: 'left',
    };
    const styleSticky = {
        // backgroundColor: 'rgba(0, 148, 117, 0.2)',
        border: '1px solid rgb(0, 148, 117)',
        color: 'darkblue',
        fontSize: '0.7rem',
        borderRadius: '2em 1em 4em / 0.5em 3em',
        boxShadow: '3px 5px #888888',
        // zIndex: '-1',
    };

    return (
        <>
            <div className='container-fluid' style={{minHeight: '42rem'}}>
                <section className='row'>
                    <div className='col-md-6 py-3'>
                        <h1 style={styleList2}>Spotlights:</h1>
                        <div className="grid">
                            {
                                props.trends.map((mytrend) => (
                                    <SpotBesch duration={150} key={mytrend.Id} trend={mytrend}/>
                                    ))
                            }
                        </div>
                    </div>
                    <div className='col-md-6 py-3' >
                        <section>
                            <h1 style={styleList2}>Beschreibung:</h1>
                        </section>
                        <section className="showspot" style={styleSticky}>
                            <h1 className="trenddis">{props.onetrend.Id}: {props.onetrend.spotlight}</h1>
                            {props.onetrend.Beschreibung.map((paragraph, key) => (
                                <p style={styleList} key={key}>{paragraph}</p>
                            ))}
                        </section>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SpotBeschreibung;