import React from 'react';
import * as dc from 'dc';
import {scaleOrdinal} from 'd3';
import { ChartTemplate } from "./chartTemplate";


const rowClusterFunc = (divRef, data0) => {
    const ColorR = ["rgb(31,130,192)", "rgb(57,55,139)", "rgb(226,0,26)", "rgb(242,148,0)", "rgb(255,220,0)", "rgb(177,200,0)"];
    const rowClusterChart = dc.rowChart(divRef);
    const rowClusterDimension = data0.dimension(function(d) {return d.type;});
    const rowClusterGroup = rowClusterDimension.group();
    rowClusterChart
        .height(125)
        .transitionDuration(1500)
        .dimension(rowClusterDimension)
        .group(rowClusterGroup)
        .gap(1)
        .margins({top:0,right:5,bottom:20,left:10})
        .colorAccessor(function (d) {return d.key;})
        .colors(scaleOrdinal(ColorR))
        .colorDomain(['Algorithms & Hybrid Archtecture','Data','Human','Materials','Planet & Space','Society'])
        .keyAccessor(function (d) {return d.key;})
        .valueAccessor(function (d) {return d.value;})
        .label(function(d){ return d.key + ': '+ d.value;});


    return rowClusterChart
};

export const RowClusterChart = props => (
    <ChartTemplate chartFunction={rowClusterFunc} />
);