import React from 'react';
import * as dc from 'dc';
import { ChartTemplate2} from "./chartTemplate2";


const numberDisplayFunc = (divRef, data0) => {
    const all = data0.groupAll();
    const ClusterGroup = all.reduceSum(d => d.bewertet);
    const numberDisplay = dc.numberDisplay(divRef);

    numberDisplay
        .group(ClusterGroup)
        .valueAccessor(d => d);

    return numberDisplay
};

export const NumberDisplay = props => (
    <ChartTemplate2 chartFunction={numberDisplayFunc} />
);