import React, { Component} from 'react';
import crossfilter from 'crossfilter2';
import { csv, format} from 'd3'
import 'dc/dc.min.css';
import my1data from './topic6.csv';

export const MYContext = React.createContext('MYContext');
export const numberFormat = format('.2f');
export const num1Format = format('.1f');

export class DataContext extends Component {
    constructor(props) {
        super(props);
        this.state={loading:false,
            hasMYDATA:false,
        };

    }

    componentDidMount() {
        if (this.state.hasMYDATA){
            return
        }
        if(this.state.loading){
            return
        }
        this.setState({loading:true});
        csv(my1data).then((data) => {
            data.forEach(d => {
                d.TopicNum = +d.TopicNum;
                d.bewertet = +d.bewertet;
                d.myResearch = +numberFormat(d.myResearch);
                d.appResearch = +numberFormat(d.appResearch);
                d.research2030 = +numberFormat(d.research2030);
                d.DE2030 = +numberFormat(d.DE2030);
                d.Disruptive = +numberFormat(d.Disruptive);
                d.Environment = +numberFormat(d.Environment);
                d.Society = +numberFormat(d.Society);
                d.one_irrelevant = +num1Format(d.one_irrelevant);
                d.two_almost_irrelevant = +num1Format(d.two_almost_irrelevant);
                d.three_moderately_relevant = +num1Format(d.three_moderately_relevant);
                d.four_quite_relevant = +num1Format(d.four_quite_relevant);
                d.five_absolutely_relevant = +num1Format(d.five_absolutely_relevant);
                d.average1 = +d.average1;
                d.irrelevant_1 = +d.irrelevant_1;
                d.almost_irrelevant_2 = +d.almost_irrelevant_2;
                d.moderately_relevant_3 = +d.moderately_relevant_3;
                d.quite_relevant_4 = +d.quite_relevant_4;
                d.absolutely_relevant_5 = +d.absolutely_relevant_5;
                d.average2 = +d.average2;
                d.resAreas = +d.resAreas;
                d.Sectors = +d.Sectors;
                d.wahl = +d.wahl;
            });

            this.data0 = crossfilter(data);
            this.setState({loading:false,hasMYDATA:true})
        })
    }

    render() {
        if(!this.state.hasMYDATA){
            return null;
        }
        return (
            <MYContext.Provider value={{data0:this.data0}}>
                <div ref={this.parent}>
                    {this.props.children}
                </div>
            </MYContext.Provider>
        );

    }
}