import React, { Component} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import logo1 from './images/logo_green.png';
import './App.css';
import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import ProjectPage from "./pages/ProjectPage";
import BackgroundPage from "./pages/BackgroundPage";
import NavBar from "./components/NavBar";
import SpotBeschreibungPage from "./pages/SpotBeschreibungPage";
import SpotBeschreibung from "./components/SpotBeschreibung";
import AppFooter from "./components/AppFooter";
import NotFoundPage from "./pages/NotFoundPage";
import Impressum from "./components/Impressum";
import ForesightdayPage from "./pages/ForesightdayPage";
import SpotPage from "./pages/SpotPage";
import SpotOnepager from "./pages/SpotOnepager";
import 'animate.css/animate.min.css';
import HeatPage from "./pages/HeatPage";
import data from './data/data-spots4';


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleLogo: true,
            trends: [],
        };
        this.toggleLogo = this.toggleLogo.bind(this);
        this.openNav = this.openNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentWillMount() {
        this.setState({
            trends: data,
        })
    }

    toggleLogo(event) {
        this.setState((prevState) => ({
          toggleLogo: !prevState.toggleLogo
        }));
    }

    openNav() {
        document.getElementById("myNav").style.width = "60%";
    }
    closeNav() {
        document.getElementById("myNav").style.width = "0%";
    }
    render() {
        return (
            <Router>
                <div className="App myimg">
                    <header className="App-header">
                        <NavBar/>
                        <h1 className={this.state.toggleLogo ? "menu-hidden" : "menu animated bounceInDown"}
                            onMouseEnter={this.toggleLogo}
                            onMouseLeave={this.toggleLogo}
                            onClick={this.openNav}
                        >Menu</h1>
                        <img src={logo1} alt="logo"
                             className={this.state.toggleLogo ? "App-logo" : "App-logo"}
                             onMouseEnter={this.toggleLogo}
                             onMouseLeave={this.toggleLogo}
                             onClick={this.openNav}
                        />
                        <Navigation closeNav={this.closeNav}/>
                    </header>
                    <div className="container-fluid page-body">
                        <Switch>
                            <Route exact path="/" component={ProjectPage} />
                            <Route exact path="/survey" component={HomePage} />
                            <Route exact path="/heat" component={HeatPage} />
                            <Route exact path="/onepager" render={(props) => {
                                return (
                                    <div className="container-fluid">
                                      <SpotPage trends={this.state.trends} />
                                    </div>
                                )
                            }} />

                            <Route exact path="/onepager/:Id" render={(props) => {
                                let trendPos = props.location.pathname.replace('/onepager/', '');
                                return (
                                    <SpotOnepager trend={this.state.trends[trendPos-1]} trends={this.state.trends}/>
                                )
                            }} />
                            <Route exact path="/foresightday" component={ForesightdayPage} />
                            <Route exact path="/beschreibung" render={(props) => {
                                return (
                                    <div className="container-fluid">
                                        <SpotBeschreibungPage trends={this.state.trends} />
                                    </div>
                                )
                            }} />
                            <Route exact path="/beschreibung/:dd" render={(props) => {
                                let trendLoc = props.location.pathname.replace("/beschreibung/", "");
                                return (
                                    <SpotBeschreibung onetrend={this.state.trends[trendLoc-1]} trends={this.state.trends}/>
                                    )
                            }}/>

                            <Route exact path="/background" component={BackgroundPage}/>
                            <Route exact path="/impressum" component={Impressum}/>
                            <Route component={NotFoundPage} />
                        </Switch>
                    </div>
                    <div className="footer">
                      <AppFooter/>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
