import React from 'react';
import * as dc from 'dc';
import {scaleBand} from 'd3';
import { ChartTemplate } from "./chartTemplate";
import './barResearch.css';

const barReasearch1Func = (divRef, data0) => {
    const barResearch1Chart = dc.barChart(divRef);
    var topicDimension = data0.dimension(d => d.Topic);
    var barResGroup = topicDimension.group().reduce(
        function(p, v) {
        p.one_irrelevant = (p.one_irrelevant || 0) + v.one_irrelevant;
        p.two_almost_irrelevant = (p.two_almost_irrelevant || 0) + v.two_almost_irrelevant;
        p.three_moderately_relevant = (p.three_moderately_relevant || 0) + v.three_moderately_relevant;
        p.four_quite_relevant = (p.four_quite_relevant || 0) + v.four_quite_relevant;
        p.five_absolutely_relevant = (p.five_absolutely_relevant || 0) + v.five_absolutely_relevant;
        p.average1 = (p.average1 || 0) + v.average1;
        return p; },
        function(p, v) {
        p.one_irrelevant = (p.one_irrelevant || 0) - v.one_irrelevant;
        p.two_almost_irrelevant = (p.two_almost_irrelevant || 0) - v.two_almost_irrelevant;
        p.three_moderately_relevant = (p.three_moderately_relevant || 0) - v.three_moderately_relevant;
        p.four_quite_relevant = (p.four_quite_relevant || 0) - v.four_quite_relevant;
        p.five_absolutely_relevant = (p.five_absolutely_relevant || 0) - v.five_absolutely_relevant;
        p.average1 = (p.average1 || 0) - v.average1;
        return p; },
        function() {
        return {one_irrelevant:0, two_almost_irrelevant:0, three_moderately_relevant:0,
            four_quite_relevant:0, five_absolutely_relevant:0, average1:0}; }
            );
    function sel_stack(i) {
              return function(d) {
                  return d.value[i];
              };
          }

    barResearch1Chart
        .height(250)
        .transitionDuration(1500)
        .dimension(topicDimension)
        .group(barResGroup, "absolutely_relevant", function (d) {return d.value["five_absolutely_relevant"] || 0; })
        .stack(barResGroup, "quite_relevant", function (d) {return d.value["four_quite_relevant"];})
        .stack(barResGroup, "moderately_relevant", sel_stack("three_moderately_relevant"))
        .stack(barResGroup, "almost_irrelevant", sel_stack("two_almost_irrelevant"))
        .stack(barResGroup, "irrelevant", sel_stack("one_irrelevant"))
        .gap(1)
        .elasticY(true)
        .clipPadding(40)
        .margins({top:0,right:10,bottom:20,left:35})
        .x(scaleBand())
        .xUnits(dc.units.ordinal)
        .xAxisLabel('Spotlights')
        .brushOn(true)
        .yAxisLabel("Zahl der Bewertungen")
        .renderLabel(true)
        .ordering(function(d) {return d.value['average1'];})
        .title(function(d) {return [d.key,
             '[' + this.layer + ']',
            d.value[this.layer],
            'Average: ' + d.value['average1'] ,].join('\n');})
        .legend(dc.legend().x(60).y(15).itemHeight(12).gap(5));

    dc.override(barResearch1Chart, 'legendables', function() {
        var items = barResearch1Chart._legendables();
        return items.reverse();
    });

    barResearch1Chart.xAxis().ticks(51);
    barResearch1Chart.xAxis().tickValues(false);

    return barResearch1Chart
};

export const BarResearch1Chart = props => (
    <ChartTemplate chartFunction={barReasearch1Func} />
);