import React from "react";
import { MYContext} from "../data/myContext";
import * as dc from "dc";


const ResetButton = props => {
  const style1 = {
    padding: '0.1rem',
    fontSize: '0.7rem',
    display: 'inline',
    cursor: 'pointer',
    float: 'right',
  };
  return ( <span style={style1} onClick={() => { props.chart.filterAll(); dc.redrawAll(); }}>Reset</span> );
};


export const ChartTemplate = props => {

  const context = React.useContext(MYContext);
  const [chart, setChartOnState] = React.useState(null);
  const data0 = context.data0;
  const div = React.useRef(null);
  React.useEffect(() => {
    const newChart = props.chartFunction(div.current, data0);
    newChart.render();
    setChartOnState(newChart);
  }, [1]);

  const chartStyles  = {
    width:'100%',
    padding: '0rem',
  };
  return (
    <div ref={div} style={chartStyles}>
       <ResetButton chart={chart} />
    </div>
  );
};