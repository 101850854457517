import React from 'react';
import backImage from '../images/auge1.jpg';

const NotFoundPage = () => (
    <div style={{minHight: "10rem"}}>
        <h1 style={{color: "orange", marginTop: "3rem"}}>404: Sie haben sich verloren! Zurück gehen!!!</h1>
        <img style={{ padding: "50px 0 0 0", width: "100%", margin: 0, opacity: 0.2}} src={backImage} alt="Back"/>
    </div>
);

export default NotFoundPage;