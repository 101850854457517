import React from 'react';
import * as dc from 'dc';
import {scaleLinear, symbolDiamond} from 'd3';
import { ChartTemplate2 } from "./chartTemplate2";
import { round} from "mathjs";


const scatterRE30Funcb = (divRef, data0) => {
    const scatterRE30 = dc.scatterPlot(divRef);
    const scatterDimension = data0.dimension(d => d.Topic);
    const scatterGroup = scatterDimension.group().reduce(
        function(p, v) {
            p.appResearch = (p.appResearch || 0.) + v.appResearch;
            p.research2030 = (p.research2030 || 0.) + v.research2030;
            p.bewertet = (p.bewertet || 0.) + v.bewertet;
            return p;},
        function(p, v) {
            p.appResearch = (p.appResearch || 0.) - v.appResearch;
            p.research2030 = (p.research2030 || 0.) - v.research2030;
            p.bewertet = (p.bewertet || 0.) - v.bewertet;
            return p;},
        function() {return {appResearch:0., research2030:0., bewertet:0.};}
        );
    let range;

    scatterRE30
        .height(275)
        .margins({top:5,right:15,bottom:30,left:25})
        .transitionDuration(1500)
        .dimension(scatterDimension)
        .group(scatterGroup)
        .renderHorizontalGridLines(true)
        .renderVerticalGridLines(true)
        .clipPadding(50)
        .symbolSize(8)
        .excludedColor('gray')
        // .brushOn(false)
        .renderLabel(false)
        .excludedSize(8)
        .excludedOpacity(0.2)
        .filterPrinter(function(filters){
            range = 'From ['+ round(filters[0][0][0],0)+','
                + round(filters[0][0][1],0) +'] to ['
                + round(filters[0][1][0],0) +','
                + round(filters[0][1][1],0) +']';
            return range; })
        .colorAccessor(d => d.value.bewertet)
        .colors(scaleLinear().domain([17,167]).range(["rgb(251,204,6)", "rgb(255,5,149)"]))
        .keyAccessor(function(d){return d.value.appResearch;})
        .valueAccessor(function(d){return d.value.research2030;})
        .y(scaleLinear().domain([3.4,5.0]))
        .x(scaleLinear().domain([2,4.2]))
        .yAxisLabel("Relevanz 2030")
        .xAxisLabel("Relevanz heute")
        .title(function(d) {return [d.key,
            'Zahl der Bewertungen: ' + d.value.bewertet ,].join('\n');})
        .symbol(symbolDiamond);


    return scatterRE30
};

export const ScatterRE30b = props => (
    <ChartTemplate2 chartFunction={scatterRE30Funcb} />
);