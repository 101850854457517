import React from 'react';
import SpotList from "../components/SpotList";
import logo_fraunhofer from "../images/isi_logo_120px.png";
import "./SpotOnepager.css";


const SpotOnepager = (props) => {
    const styleList = {
        padding: '0rem',
        textAlign: 'left',
    };
    const images = require.context('../images/patent', true);
    let imgyear, imgpat;
    try {
        imgpat = images('./id' + props.trend.Id + '_pat.png');
    } catch (e) {
        // ...
    }
    try {
        imgyear = images('./id' + props.trend.Id + '_year.png');
    } catch (e) {
        // ...
    }


    return (

        <div className="onetitle" style={{minHeight: '42rem'}}>
            <h1 className="pageh1title">Durch klicken auf einzelne Spotlights werden Onepager angezeigt</h1>
            {/*<section className="row grid animated bounceInUp">*/}
            <section className="row grid">
                <div className="col-md-12">
                    {
                        props.trends.map((trend) => (
                            <SpotList duration={150} key={trend.Id} trend={trend}/>
                            ))
                    }
                </div>
            </section>

            <section className="row px-3" style={{marginTop: '1rem'}}>
                <div className="col-md-10">
                    <h3 className="float-left"> {props.trend.spotlight}</h3>
                </div>
                <div className="col-md-2">
                    <p className="float-right">Last update: {props.trend.lastupdate}</p>
                    {/*<img src={logo_fraunhofer} alt="Logo" style={{float: 'right', maxWidth: '60%'}}/>*/}
                </div>
            </section><hr/>

            <section className="row telo px-3 py-1">
                <div className="col-md-9 ">
                    <p className="telo1"> {props.trend.idea}</p>
                    <section className="row telomain">
                        <div className="col-md-6">
                            <h3>Description</h3>
                            {props.trend.Beschreibung.map((paragraph, key) => (
                               <p style={styleList} key={key}>{paragraph}</p>
                            ))}
                            <h3>Long-Term Perspectives</h3>
                            {props.trend.longTerm.map((paragraph, key) => (
                               <p style={styleList} key={key}>{paragraph}</p>
                            ))}
                        </div>
                        <div className="col-md-6">
                            <h3>Selected Recent Development</h3>
                            {props.trend.recent.map((paragraph, key) => (
                               <p style={styleList} key={key}>{paragraph}</p>
                            ))}
                            <hr/>
                            <h3>Social and Business Media</h3>
                            <img src={imgyear} alt={props.trend.spotlight}/>

                        </div>
                    </section>


                </div>
                <div className="col-md-3 teloright">
                    <h3 className="mymargin">Cluster</h3>
                    <p className="mymargin"> {props.trend.cluster}</p>
                    <h3 className="mymargin">Maturity Level</h3>
                    <p className="mymargin"> {props.trend.mlevel}</p><hr/>
                    <h3 className="mymargin">Areas of Impact</h3>
                    <p className="mymargin"> {props.trend.impact}</p>
                    <h3 className="mymargin">Possible application scenario</h3>
                    <div className="myli">
                        <ul className="demo">
                            {
                                props.trend.applicationscenario.map((Detail) => {
                                    return (
                                        <li key={Detail.szenario} style={styleList}>
                                            {Detail.szenario}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div><hr/>

                    <h3 className="mymargin">Publications 2016 - 2018 total: {props.trend.pubNum}</h3>
                    <p className="mymargin"> {props.trend.pubLead}</p>
                    <p className="mymargin"> {props.trend.pubAccess}</p><hr/>

                    <h3 className="mymargin">Patents Families total: {props.trend.patNum}</h3>
                    <p className="mymargin"> {props.trend.patLead}</p>
                    <p className="mymargin"> {props.trend.patAccess}</p><hr/>
                    <h3>Main Patent Assignees</h3>
                    <img src={imgpat} alt={props.trend.spotlight}/>
                    <h3>Sources</h3>
                    <ul className="demo">
                        {
                            props.trend.mylinks.map((Detail) => {
                                return (
                                    <li key={Detail.href} style={styleList}>
                                        <a href={Detail.href}>{Detail.href}</a>
                                    </li>
                                );
                            })
                        }
                    </ul>

                </div>
            </section>

        </div>
    )
}

export default SpotOnepager;