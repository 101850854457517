import React from 'react';
import { FaRegEnvelope } from 'react-icons/fa';
import logo from '../images/logomy1.png';

const Impressum = () => (
    <article className="page-section text-center py-3">
        <section className="container-fluid">
            <div className="row justify-content-center">
                <section className="col-md-12 col-lg-8 impre">
                    <h1>Fraunhofer Foresight Projekt</h1>
                    <hr/><br/>
                    <h2>ISI, INT, IAO, IMV
                    </h2><br/>
                    <h3>
                        Fraunhofer Think Tank
                    </h3><br/>
                    <hr/><br/>
                    <p><img src={logo} alt="Fraunhofer Logo" width="190px"/></p>
                    <p>Fraunhofer Institute for Systems and Innovation Research <br/>
                    Breslauer Straße 48 <br/> 76139 Karlsruhe, Germany </p>
                    <hr/>
                    <br/> <br/> <br/>
                    <h3><b>Contact</b></h3><br/>
                    <p>Elna Schirrmeister
                        <a href="mailto:elna.schirrmeister@isi.fraunhofer.de?Subject=Foresight2019%20WEB_Seite"
                           target="_top"> <FaRegEnvelope/> Send E-Mail
                        </a>
                    </p>
                    <p>Svetlana Meissner
                        <a href="mailto:svetlana.meissner@isi.fraunhofer.de?Subject=Foresight2019%20WEB_Seite"
                           target="_top"> <FaRegEnvelope/> Send E-Mail
                        </a>
                    </p><br/>
                    <p>
                        Fraunhofer Institute for Systems and Innovation Research, Germany
                    </p>
                    <br/>
                </section>
            </div>
        </section>
    </article>
);

export default Impressum;