import React from 'react';
import * as dc from 'dc';
import {scaleLinear} from 'd3';
import { ChartTemplate3} from "./chartTemplate3";


const heatResearchFunc = (divRef, data3) => {
    const heatResearchChart = dc.heatMap(divRef);
    const heatResDim = data3.dimension(function(d) { return [+d.TopicNum, d.AreaName]; });
    // const myHDimension = data3.dimension(function(d) {return [d.TopicNum, d.AreaName];});
    // const heatResGroup = heatResDim.group().reduceSum(function(d) { return d.WertArea; });
    const heatResGroup = heatResDim.group().reduce(
        function(p, v) {
            p.Cluster += v.Cluster;
            p.AreaNum += v.AreaNum;
            p.WertArea = (p.WertArea || 0.) + v.WertArea;
            p.TopicA += v.TopicA;
            return p;},
        function(p, v) {
            p.Cluster -= v.Cluster;
            p.AreaNum -= v.AreaNum;
            p.WertArea = (p.WertArea || 0.) - v.WertArea;
            p.TopicA -= v.TopicA;
            return p;},
        function() {return {Cluster:"", AreaNum: 0, WertArea:0., TopicA:""};}
        );
    const heatColorMapping = scaleLinear().domain([0, 0.1, 0.5, 0.7])
        .range(["white", "rgb(238,241,83)","rgb(242,134,173)","rgb(244,0,31)"]);

    heatResearchChart
        .height(250)
        .margins({top:5,right:5,bottom:20,left:180})
        .dimension(heatResDim)
        .group(heatResGroup)
        .keyAccessor(function(d) { return d.key[0]; })
        .valueAccessor(function(d) { return d.key[1]; })
        .colorAccessor(function(d) { return d.value.WertArea; })
        // .keyAccessor(function(d) { return d.key.TopicANum; })
        // .valueAccessor(function(d) { return d.key.AreaName; })
        // .colorAccessor(function(d) { return d.value.WertArea; })
        .title(function(d) {
            return "Research Area:   " + d.key[1] + "\n" +
                    "Cluster:  " + d.value.Cluster + "\n" +
                    "Topic:  " + d.value.TopicA + "\n" +
                    "Wert: " + d.value.WertArea;})
        .colors(heatColorMapping);


    return heatResearchChart
};

export const HeatResearchChart = props => (
    <ChartTemplate3 chartFunction={heatResearchFunc} />
);