import React from 'react';
import * as dc from 'dc';
import {ChartTemplate3} from "./chartTemplate3";

const dataCountFunc3 = (divRef, data3) => {
    const dataCount3 = dc.dataCount(divRef);
    const all3 = data3.groupAll();

    dataCount3
        .dimension(data3)
        .group(all3)
        .html({
            some: "<span>%filter-count</span> ausgewählt von <span>%total-count</span> Trends |" +
                 "<a href='' > Reset</a>",
            all: "Spezielle Felder können Sie durch anklicken selektieren."
        })
        ;

    return dataCount3
};

export const DataCount3 = props => (
    <ChartTemplate3 chartFunction={dataCountFunc3} />
);