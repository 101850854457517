import React from 'react';
import { Dashboard} from "../components/Dashboard";
import { DiSizzlejs } from "react-icons/di";
import { GiLookAt } from "react-icons/gi";

const HomePage = () => (
    <>
        <h1 className="animated rollIn"><DiSizzlejs/> Survey Results<DiSizzlejs/></h1>
        <Dashboard />
    </>
);

export default HomePage;