import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import './AppFooter.css';

const AppFooter = () => (
    <footer className="site-footer d-flex justify-content-center">
        <section className="layout-social py-2">
            <p className="colwhite">
                <a className="text-light px-2" href="https://www.isi.fraunhofer.de/">© Fraunhofer ISI 2020</a> |
                <a className="text-light px-2" href="https://www.isi.fraunhofer.de/en/competence-center/foresight.html">
                    <FaEye/> Foresight
                </a> |
                <Link className="text-light px-2" to="/impressum">Impressum</Link> |
                <a className="text-light px-2"
                      href="https://www.isi.fraunhofer.de/de/datenschutzerklaerung.html">Datenschutz</a>
                |
            </p>
        </section>
    </footer>

);

export default AppFooter;