import React from 'react';
import { Dashboard2} from "../components/Dashboard2";
import { DiSizzlejs } from "react-icons/di";
import { GiLookAt } from "react-icons/gi";

const HeatPage = () => (
    <>
        <h1 className="animated rollIn"><DiSizzlejs/> Survey Heatmap <DiSizzlejs/></h1>
        <Dashboard2 />
    </>
);

export default HeatPage;