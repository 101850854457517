import React from 'react';
import * as dc from 'dc'
import {ChartTemplate2} from "./chartTemplate2";

const wahlBoxFunc = (divRef, data0) => {
    const wahlBox = dc.cboxMenu(divRef);
    const sunDimension = data0.dimension(d => d.type);

    wahlBox
        .dimension(sunDimension)
        .transitionDuration(1500)
        .group(sunDimension.group())
        .controlsUseVisibility(false)
        .multiple(true)
        .filterDisplayed(function () {
            return true;
        });


    return wahlBox
};

export const WahlBox = props => (
    <ChartTemplate2 chartFunction={wahlBoxFunc} />
);