import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

const Navigation = props => (
    <div id="myNav" className="overlay">
        <button className="closebtn" onClick={() => props.closeNav()}>&times;</button>
        <div className="overlay-content">
            <Link to="/" onClick={() => props.closeNav()}>Project</Link>
            <Link to="/survey" onClick={() => props.closeNav()}>Survey</Link>
            <Link to="/heat" onClick={() => props.closeNav()}>Heatmaps</Link>
            {/*<Link to="/beschreibung" onClick={() => props.closeNav()}>/Link>*/}
            <Link to="/onepager" onClick={() => props.closeNav()}>Spotlights</Link>
            <Link to="/foresightday" onClick={() => props.closeNav()}>Foresight Day</Link>
            <Link to="/background" onClick={() => props.closeNav()}>Background</Link>
            <Link to="/impressum" onClick={() => props.closeNav()}>Impressum</Link>
            <Link to="/services" onClick={() => props.closeNav()}>Our services</Link>
        </div>
    </div>
);

export default Navigation;