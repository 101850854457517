import React from 'react';
import * as dc from 'dc';
import {format, scaleBand} from 'd3';
import { ChartTemplate3} from "./chartTemplate3";

export const numFormat = format('.1f');
const heatREBarFunc = (divRef, data3) => {

    const heatREBarChart = dc.barChart(divRef);
    const resDim = data3.dimension(function(d) { return d.TopicA; });
    const resGroup = resDim.group().reduceSum(function(d) { return d.WertArea; });

    heatREBarChart
        .height(220)
        .dimension(resDim)
        .group(resGroup)
        .keyAccessor(function(d) { return d.key; })
        .valueAccessor(function(d) { return numFormat(d.value); })
        .transitionDuration(1500)
        .ordinalColors(['#61dafb'])
        .gap(1)
        .margins({top:5,right:8,bottom:120,left:190})
        .x(scaleBand())
        .xUnits(dc.units.ordinal)
        .ordering(function(d) {return d.value;})
        .brushOn(false)
        .renderLabel(false)
        .legend(dc.legend().x(210).y(10).itemHeight(10).gap(5).horizontal(true)
            .legendText("Average number of research areas"))
        ;
    heatREBarChart.yAxis().ticks(5);

    heatREBarChart.on('renderlet',
    function (chart_x) {
        chart_x.selectAll("g.axis.x text")
          .attr('dx', '-15')
          .attr('dy', '-5')
          .attr('transform', "rotate(-90)");
    });


    return heatREBarChart
};

export const HeatREBarChart = props => (
    <ChartTemplate3 chartFunction={heatREBarFunc} />
);