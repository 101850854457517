import React from 'react';
import SpotList from "../components/SpotList";

const SpotPage = (props) => {
    return (
        <div className="onetitle" style={{minHeight: '42rem'}}>
            <h1 style={{margin: '0.3rem'}}>Durch klicken auf einzelne Spotlights werden Onepager angezeigt!</h1>
            <section className="row grid animated bounceInUp">
                <div className="col-md-12">
                    {
                        props.trends.map((trend) => (
                            <SpotList duration={150} key={trend.Id} trend={trend}/>
                        ))
                    }
                </div>
            </section>

        </div>
    )
}

export default SpotPage;