import React from 'react';
import './ProjectPage.css';
import broschure from '../data/5795 Foresight Fraunhofer_EN_br.pdf';
import bericht from '../data/Abschlussbericht _V2.pdf';
import phasa2short from '../data/phase2short.pdf';
import phasa2img from '../images/phase2b.png';
import broschureimg from '../images/broschureImage.png';
import ModalImage from "react-modal-image";
import kreisviolet from '../images/kreisviolet.png';
import kreisvioletShow from '../images/kreisvioletShow.png';
import kreis1 from '../images/kreis1.png';
import kreis2 from '../images/kreis2.png';
import kreis3 from '../images/kreis3.png';
import kreis4 from '../images/kreis4.png';
import kreis5 from '../images/kreis5.png';
import kreis1Show from '../images/kreis1Show.png';
import kreis2Show from '../images/kreis2Show.png';
import kreis3Show from '../images/kreis3Show.png';
import kreis4Show from '../images/kreis4Show.png';
import kreis5Show from '../images/kreis5Show.png';

const ProjectPage = () => (
    <div className="container-fluid">
        <h1>Fraunhofer Foresight Project</h1><hr/>
        <div className="px-3 py-3">
            <h3 className="round1">Phase 1: Future topics with relevance to application-oriented research</h3>
            <p>51 topics of high relevance to applied research in 2030 were identified.</p>
            <section className="px-5">
                <div className="mypolaroid">
                    <a className="mya2" href={broschure} download>
                        <img src={broschureimg} alt="Broschure" className="image"/>
                        <div className="imgcontainer">
                            <p>Broschure als pdf runterladen</p>
                        </div>
                    </a>
                </div>
            </section>
        </div><hr/>


        <div className="px-3 py-3">
            <h3>Phase 2: Scanning Revised</h3>
            <p>Textmining von strukturierten und unstrukturierten Daten</p>
            <div className="divrelativ">
                <ModalImage className="kreis1"
                    small={kreis1}
                    large={kreis1Show}
                    alt="Beispiel: CSIR - South Africa; Overlay Map"
                />
                <ModalImage className="violet"
                    small={kreisviolet}
                    large={kreisvioletShow}
                    alt="Lessons Learned - Zusammenfassung"
                />
                <ModalImage className="kreis2"
                    small={kreis2}
                    large={kreis2Show}
                    alt="Methodik Podcast Analyse"
                />
                <ModalImage className="kreis3"
                    small={kreis3}
                    large={kreis3Show}
                    alt="Fraunhofer Foresight Day"
                />
                <ModalImage className="kreis4"
                    small={kreis4}
                    large={kreis4Show}
                    alt="Neue Spotlights aus der Projektphase 2"
                />
                <ModalImage className="kreis5"
                    small={kreis5}
                    large={kreis5Show}
                    alt="(Teil-)Automatisierte Identifikation potenzieller Anwendungsfelder"
                />
            </div>
            {/*<a href={phasa2short} download>*/}
            {/*    <img src={phasa2img} alt="Phase 2" width="100%"/>*/}
            {/*</a>*/}
            <a className="mya" href={bericht} download>Bericht als pdf runterladen</a>
            <a className="mya" href={phasa2short} download>Folien als pdf runterladen</a>
        </div>


    </div>
);

export default ProjectPage;