import React from 'react';
import { Link } from 'react-router-dom';
import './SpotBesch.css';

const SpotBesch = (props) => (
    <div className="animated bounceInUp">
        <Link className='float-left story-list-item' to={`/beschreibung/${props.trend.Id}`}>
            <h3>{props.trend.Id}: {props.trend.spotlight}</h3>
        </Link>

    </div>
);

export default SpotBesch;