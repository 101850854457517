import React from 'react';
import * as dc from 'dc';
import {ChartTemplate} from "./chartTemplate";

const dataCountFunc = (divRef, data0) => {
    const dataCount = dc.dataCount(divRef);
    const all0 = data0.groupAll();

    dataCount
        .dimension(data0)
        .group(all0)
        .html({
            some: "<span>%filter-count</span> ausgewählt von <span>%total-count</span> Trends |" +
                 "<a href='' > Reset</a>",
            all: "Spezielle Felder können Sie durch anklicken selektieren."
        })
        ;

    return dataCount
};

export const DataCount = props => (
    <ChartTemplate chartFunction={dataCountFunc} />
);