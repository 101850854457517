import React from "react";
import { DataContext} from "../data/myContext";
import { DataContext3} from "../data/my3Context";
import { HeatResearchChart} from "../charts/heatResearch";
import './Dashboard.css';
import {DataCount3} from "../charts/DataCount3";
import {HeatREBarChart} from "../charts/heatREBar";


export const Dashboard2 = (props) => {

    return(
        <DataContext3>
            <div className="container-fluid">
                <section className="row layout-rib d-flex align-items-center">
                    <div className="col-12 justify-content-center animated fadeInUp">
                        <h2 id="data-count2"><DataCount3/></h2>
                    </div>
                </section>
                <section className="row">
                    <HeatREBarChart/>
                    <div className="col-12">
                        <HeatResearchChart/>

                    </div>
                </section>
            </div>
        </DataContext3>
    )
};