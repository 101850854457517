import React from "react";
import { MYContext} from "../data/myContext";

export const ChartTemplate2 = props => {

  const context = React.useContext(MYContext);
  const [chart, setChartOnState] = React.useState(null);
  const data0 = context.data0;
  const div = React.useRef(null);
  React.useEffect(() => {
    const newChart = props.chartFunction(div.current, data0);
    newChart.render();
    setChartOnState(newChart);
  }, [1] );

  const chartStyles  = {
    width:'100%',
    boxSizing:'border-box'
  };
  return (
    <div ref={div} style={chartStyles}></div>
  );
};