import React from 'react';
import * as dc from 'dc';
import {scaleBand} from 'd3';
import { ChartTemplate } from "./chartTemplate";
import './barResearch.css';

const barReasearch2Func = (divRef, data0) => {
    const barResearch2Chart = dc.barChart(divRef);
    var topicDimension = data0.dimension(d => d.Topic);
    var barResGroup2 = topicDimension.group().reduce(
        function(p, v) {
            p.irrelevant_1 = (p.irrelevant_1 || 0.) + v.irrelevant_1;
            p.almost_irrelevant_2 = (p.almost_irrelevant_2 || 0.) + v.almost_irrelevant_2;
            p.moderately_relevant_3 = (p.moderately_relevant_3 || 0.) + v.moderately_relevant_3;
            p.quite_relevant_4 = (p.quite_relevant_4 || 0.) + v.quite_relevant_4;
            p.absolutely_relevant_5 = (p.absolutely_relevant_5 || 0.) + v.absolutely_relevant_5;
            p.average2 = (p.average2 || 0.) + v.average2;
            return p;},
        function(p, v) {
            p.irrelevant_1 = (p.irrelevant_1 || 0.) - v.irrelevant_1;
            p.almost_irrelevant_2 = (p.almost_irrelevant_2 || 0.) - v.almost_irrelevant_2;
            p.moderately_relevant_3 = (p.moderately_relevant_3 || 0.) - v.moderately_relevant_3;
            p.quite_relevant_4 = (p.quite_relevant_4 || 0.) - v.quite_relevant_4;
            p.absolutely_relevant_5 = (p.absolutely_relevant_5 || 0.) - v.absolutely_relevant_5;
            p.average2 = (p.average2 || 0.) - v.average2;
            return p;},
        function() {return {irrelevant_1:0., almost_irrelevant_2:0., moderately_relevant_3:0., quite_relevant_4:0.,
            absolutely_relevant_5:0., average2:0.};}
        );
    function sel_stack(i) {
              return function(d) {
                  return d.value[i];
              };
          }

    barResearch2Chart
        .height(250)
        .transitionDuration(1500)
        .dimension(topicDimension)
        .group(barResGroup2, "absolutely_relevant_5", sel_stack('absolutely_relevant_5'))
        .stack(barResGroup2, "quite_relevant_4", sel_stack('quite_relevant_4'))
        .stack(barResGroup2, "moderately_relevant_3", sel_stack('moderately_relevant_3'))
        .stack(barResGroup2, "almost_irrelevant_2", sel_stack('almost_irrelevant_2'))
        .stack(barResGroup2, "irrelevant_1", sel_stack('irrelevant_1'))
        .gap(1)
        .elasticY(true)
        .clipPadding(20)
        .margins({top:0,right:10,bottom:20,left:35})
        .x(scaleBand())
        .xUnits(dc.units.ordinal)
        .xAxisLabel('Spotlights')
        .brushOn(true)
        // .yAxisLabel("Zahl der Bewertungen")
        .renderLabel(true)
        .ordering(function(d) {return d.value['average2'];})
        .title(function(d) {return [d.key,
            this.layer + ': ' + d.value[this.layer],
            'Average: ' + d.value['average2'] ,].join('\n');})
        .legend(dc.legend().x(60).y(15).itemHeight(12).gap(5));

    dc.override(barResearch2Chart, 'legendables', function() {
        var items = barResearch2Chart._legendables();
        return items.reverse();
    });

    barResearch2Chart.xAxis().ticks(51);
    barResearch2Chart.xAxis().tickValues(false);

    return barResearch2Chart
};

export const BarResearch2Chart = props => (
    <ChartTemplate chartFunction={barReasearch2Func} />
);