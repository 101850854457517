import React from 'react';
import * as dc from 'dc';
import {scaleOrdinal} from 'd3';
import { ChartTemplate } from "./chartTemplate";


const pieClusterFunc = (divRef, data0) => {
    const ColorAccent = ["#e41a1c","#377eb8","#4daf4a","#984ea3","#ff7f00","#ffff33","#a65628","#f781bf","#999999"];
    const pieClusterChart = dc.pieChart(divRef);
    const ClusterDimension = data0.dimension(d => d.Cluster);
    const ClusterGroup = ClusterDimension.group().reduceSum(d => d.bewertet);

    pieClusterChart
        .height(170)
        .transitionDuration(2000)
        .dimension(ClusterDimension)
        .group(ClusterGroup)
        .radius(60)
        .innerRadius(40)
        // .cx(145)
        // .cy(150)
        .renderLabel(false)
        .legend(dc.legend().x(5).y(0).itemHeight(10).gap(4))
        .colors(scaleOrdinal(ColorAccent))
        .colorDomain(['Algorithms & Hybrid Archtecture','Data','Human','Materials','Planet & Space','Society'])
        .colorAccessor(function(d){ return d.key;})
        .keyAccessor(function(d){return d.key;})
        .valueAccessor(function(d){return d.value;})
        .title(d => d.key + ': Total ' + d.value)
        ;

    return pieClusterChart
};

export const PieClusterChart = props => (
    <ChartTemplate chartFunction={pieClusterFunc} />
);