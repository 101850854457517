import React from 'react';
import * as dc from 'dc'
import {ChartTemplate2} from "./chartTemplate2";

const janeinBoxFunc = (divRef, data0) => {
    const janeinBox = dc.cboxMenu(divRef);
    const sunDimension = data0.dimension(d => d.wahl);

    janeinBox
        .height(30)
        .dimension(sunDimension)
        .transitionDuration(1500)
        .group(sunDimension.group())
        .controlsUseVisibility(false)
        .multiple(true)
        .filterDisplayed(function () {
            return true;
        });


    return janeinBox
};

export const JaneinBox = props => (
    <ChartTemplate2 chartFunction={janeinBoxFunc} />
);