import React from 'react';
import fd0 from '../images/foresightday0.png';
import fd1 from '../images/foresightday1.png';
import fd2 from '../images/foresightday2.png';
import fod1 from '../images/foresightday/fraunhoferforesightday_1.png';
import fod2 from '../images/foresightday/fraunhoferforesightday_2.png';
import fod3 from '../images/foresightday/fraunhoferforesightday_3.png';
import fod4 from '../images/foresightday/fraunhoferforesightday_4.png';
import fod5 from '../images/foresightday/fraunhoferforesightday_5.png';
import fod6 from '../images/foresightday/fraunhoferforesightday_6.png';
import fod7 from '../images/foresightday/fraunhoferforesightday_7.png';
import fod8 from '../images/foresightday/fraunhoferforesightday_8.png';
import fod9 from '../images/foresightday/fraunhoferforesightday_9.png';
import fod10 from '../images/foresightday/fraunhoferforesightday_10.png';
import fod11 from '../images/foresightday/fraunhoferforesightday_11.png';
import fod12 from '../images/foresightday/fraunhoferforesightday_12.png';
import fod13 from '../images/foresightday/fraunhoferforesightday_13.png';
import fod14 from '../images/foresightday/fraunhoferforesightday_14.png';

import './ProjectPage.css';

const ForesightdayPage = () => (
    <div className="container-fluid">
        <h1 className="px-3 py-1">Fraunhofer Foresight Day</h1><hr/>

        <section className="px-3 py-3">
            <h3 className="fdh3">Übersicht zum Ablauf des Tages</h3>
            <p className="mybold">Ort:</p>
            <p className="intext">Spreespeicher Eventlocation</p>
            <p className="mybold">Raum:</p>
            <p className="intext">Capitol Yard Lounge</p>
            <p className="mybold">Adresse:</p>
            <p className="intext">Stralauer Allee 2, 10245 Berlin</p>
            <p className="mybold">Datum:</p>
            <p className="intext">Freitag, 08.11.2019</p>
        </section><hr/>

        <section className="px-3 py-3 ablauf">
            <img className="fdimages" style={{position: "absolute", left: "52%", zIndex: "+2"}} src={fod1} alt="Foresight Day" width="20%"/>
            <img className="fdimages" style={{position: "absolute", left: "75%", zIndex: "+2"}} src={fod2} alt="Foresight Day" width="20%"/>

            <h2>9:00 - 9:30</h2>
            <h2 className="mybold" style={{marginBottom: "2rem"}}>Soft-Opening</h2>
            <h2>9:30 - 10:00</h2>
            <h2 className="mybold">Begrüßung und Impulsvorträge</h2>
            <h2 className="myital">Prof. Jakob Edler</h2>
            <h2 className="myital">Cornelia Reimoser</h2>
            <h2 className="myital">Elna Schirrmeister</h2>
            <img className="fdimages" style={{position: "relative", left: "1%", top: "13%", zIndex: "+1"}} src={fod3} alt="Foresight Day" width="60%"/>

        </section><hr/>
        <section className="px-3 py-3 ablauf">

            <h2>10.00 - 12:30 Uhr</h2>
            <h2 className="mybold">Spotlight-Diskussionsrunden an Thementischen</h2>
            <h3 className="myital">Leitfragen für die Kleingruppendiskussion</h3>
            <ul>
                <li>Was könnte Fraunhofer 2030 dazu beigetragen haben, dass die Potenziale erschlossen wurden?</li>
                <li>Welche Forschungsfragen und Förder-möglichkeiten sind besonders relevant?</li>
                <li>Welche Konkurrenztechnologien haben sich 2030 durchgesetzt?</li>
                <li>Welche Hemmnisse haben diese Technologie/Entwicklung ausgebremst?</li>
                <li>Was bedeutet die integrierte Betrachtung dieser Spotlights für die Forschung?</li>
                <li>Welche neuen Themen sind hier relevant?</li>
            </ul>
            <img className="fdimages" style={{position: "relative", left: "1%", top: "13%"}} src={fod4} alt="Foresight Day" width="60%"/>
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", zIndex: "+1"}} src={fod5} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Thementisch:</h2>
                <h2 className="mybold">Carbon Dioxid Management</h2>
                <h3 className="myital">Ralph Gutknecht</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "15%", zIndex: "+1"}} src={fod6} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Thementisch:</h2>
                <h2 className="mybold">Civic Tech & Smart Contracts </h2>
                <h3 className="myital">Juliane Welz, Inga Döbel</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "1%", zIndex: "+1"}} src={fod7} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Thementisch:</h2>
                <h2 className="mybold">Re-Economy</h2>
                <h3 className="myital">Björn Moller</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "15%", zIndex: "+1"}} src={fod8} alt="Foresight Day" width="30%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Thementisch:</h2>
                <h2 className="mybold">Künstliche Intelligenz - Machine Learning</h2>
                <h3 className="myital">Svetlana Meissner, Lukas Keicher</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "1%", zIndex: "+1"}} src={fod9} alt="Foresight Day" width="60%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Thementisch:</h2>
                <h2 className="mybold">Organ-On-A-Chip</h2>
                <h3 className="myital">Antonino Ardilio, Milos Jovanović</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf" style={{overflow: "auto"}}>
            <img className="fdimages" style={{float: "right", marginRight: "1%", zIndex: "+1"}} src={fod10} alt="Foresight Day" width="50%"/>
            <h2 className="" style={{marginTop: "2rem"}}>13:30 - 14:00</h2>
            <h2 className="mybold">Projektvorstellung</h2>
            <h3 className="myital">Elna Schirrmeister, Antonino Ardilio</h3>

        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <h2 className="mybold" style={{marginTop: "2rem"}}>14:00 - 15:30</h2>
                <h2 className="mybold">Foresight-Schnupperkurs</h2>
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "1%", zIndex: "+1"}} src={fod11} alt="Foresight Day" width="50%"/>
                <h2 className="mybold">Szenarien und Storytelling</h2>
                <h3 className="myital">Ralph Gutknecht</h3>
                <h3 className="myital">Sibylle Hermann</h3>
                <h3 className="myital">Elna Schirrmeister</h3>
            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "15%", zIndex: "+1"}} src={fod12} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Visioning</h2>
                <h3 className="myital">Annamaria Riemer</h3>
                <h3 className="myital">Juliane Welz</h3>
                <h3 className="myital">Inga Döbel</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "1%", zIndex: "+1"}} src={fod13} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Publikations- und Patenanalyse</h2>
                <h3 className="myital">Milos Jovanović</h3>
                <h3 className="myital">Svetlana Meissner</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <div className="container" style={{overflow: "auto"}}>
                <img className="fdimages" style={{float: "right", marginRight: "15%", zIndex: "+1"}} src={fod14} alt="Foresight Day" width="50%"/>
                <h2 className="mybold" style={{marginTop: "2rem"}}>Roadmapping</h2>
                <h3 className="myital">Antonino Ardilio</h3>
                <h3 className="myital">Björn Moller</h3>

            </div>
        </section><hr/>
        <section className="px-3 py-3 ablauf">
            <h2 className="" style={{marginTop: "2rem"}}>16:00 - 16:30</h2>
            <h2 className="mybold">Prämierung und Abschluss</h2>

        </section><hr/>



    </div>

);

export default ForesightdayPage;