import React, { Component} from 'react';
import crossfilter from 'crossfilter2';
import { csv, format} from 'd3'
import 'dc/dc.min.css';
import my3data from './HeatResAreas.csv';

export const MY3Context = React.createContext('MY3Context');
export const numberFormat = format('.2f');
export const num1Format = format('.1f');

export class DataContext3 extends Component {
    constructor(props) {
        super(props);
        this.state={loading:false,
            hasMY3DATA:false,
        };

    }

    componentDidMount() {
        if (this.state.hasMY3DATA){
            return
        }
        if(this.state.loading){
            return
        }
        this.setState({loading:true});
        csv(my3data).then((data) => {
            data.forEach(d => {
                d.WertArea = +numberFormat(d.WertArea);
                d.TopicNum = +d.TopicNum;
                // d.AreaNum = +d.AreaNum;
            });

            this.data3 = crossfilter(data);
            this.setState({loading:false,hasMY3DATA:true})
        })
    }

    render() {
        if(!this.state.hasMY3DATA){
            return null;
        }
        return (
            <MY3Context.Provider value={{data3:this.data3}}>
                <div ref={this.parent}>
                    {this.props.children}
                </div>
            </MY3Context.Provider>
        );

    }
}