import React from "react";
import { DataContext} from "../data/myContext";
import { DataCount} from "../charts/DataCount";
import { BarResearch1Chart} from "../charts/barResearch1";
import { BarResearch2Chart} from "../charts/barResearch2";
import { RowClusterChart} from "../charts/rowCluster";
import { PieClusterChart} from "../charts/pieCluster";
import { WahlBox} from "../charts/wahlBox";
import { ScatterRE30b} from "../charts/scatterRE30b";
import { ScatterSE} from "../charts/scatterSE";
import { ScatterDEDI} from "../charts/scatterDEDI";
import { JaneinBox} from "../charts/janeinBox";
import './Dashboard.css';
import '../charts/barResearch.css';
import {NumberDisplay} from "../charts/numberDisplay";


export const Dashboard = (props) => {
    const styleList = {
        display: 'block',
        position: 'absolute',
        left: '38%',
        top: '38%',
        zIndex: 1,
        opacity: 0.5,
        color: 'darkcyan',
        padding: '0rem',
        fontSize: '2.3rem',
        textAlign: 'left',
    };
    return(
        <DataContext>
            <div className="container-fluid">
                <section className="row layout-rib d-flex align-items-center">
                    <div className="col-12 justify-content-center animated fadeInUp">
                        <h2 id="data-count"><DataCount/></h2>
                    </div>
                </section>

                <section className="row ">
                    <div className="col-md-6 mycolumn animated slideInLeft" id="barResearchID">
                        <h3>In your opinion, how relevant is this topic for applied research today? </h3>
                        <BarResearch1Chart/>
                    </div>
                    <div className="col-md-6 mycolumn animated slideInRight" id="barResearchID2" >
                        <h3>In your opinion, how relevant would be this topic in 2030? </h3>
                        <BarResearch2Chart/>
                    </div>
                </section>
                <section className="row">
                    <div className="col-md-2 wahlBox janeinBox animated fadeInUp" id="rowCluster">
                        <RowClusterChart/>
                        <JaneinBox/>
                    </div>
                    <div className="col-md-2 justify-content-center animated fadeInUp" id="pieCluster">
                        <PieClusterChart/>
                        <div style={styleList}><NumberDisplay /></div>
                    </div>
                    <div className="col-md-2 wahlBox justify-content-left">
                        <WahlBox/>
                    </div>
                </section>
                <section className="row">
                    <div className="col-md-4 px-3 scatter animated fadeInUp">
                        <ScatterRE30b/>
                    </div>
                    <div className="col-md-4 px-3 scatter animated fadeInUp">
                        <ScatterSE/>
                    </div>
                    <div className="col-md-4 px-3 scatter animated fadeInUp">
                        <ScatterDEDI/>
                    </div>
                </section>
            </div>
        </DataContext>
    )
};