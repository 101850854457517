import React from 'react';
import { Link } from 'react-router-dom';
import './StoryList.css';

const SpotList = (props) => (
    <div className="">
        <Link className='float-left spot-list-item' to={`/onepager/${props.trend.Id}`}>
            <h3>{props.trend.Id}: {props.trend.spotlight}</h3>
        </Link>

    </div>
);

export default SpotList;