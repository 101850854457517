import React from 'react';
import SpotBesch from "../components/SpotBesch";
import { GiFingernail } from "react-icons/gi";


const SpotBeschreibungPage = (props) => {
    const styleList2 = {
        color: 'rgba(6,6,6,0.77)',
        padding: '0rem',
        fontSize: '1.5rem',
        marginRight: '0.5rem',
        textAlign: 'left',
    };

    return (
        <>
            <div className='container-fluid' style={{minHeight: '42rem'}}>
                <section className='row'>
                    <div className='col-md-6 py-3'>
                        <h1 style={styleList2}>Beschreibung wird durch anklicken angezeigt</h1>
                        <div className="grid animated bounceInUp">
                            {
                                props.trends.map((trend) => (
                                   <SpotBesch duration={150} key={trend.Id} trend={trend}/>
                                ))
                            }
                        </div>

                    </div>
                    <div className='col-md-6 py-3' >
                        <div className="">
                            <h1 style={styleList2}>Beschreibung</h1>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SpotBeschreibungPage;