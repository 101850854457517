import React from 'react';
import * as dc from 'dc';
import {scaleLinear, symbolDiamond} from 'd3';
import { ChartTemplate2 } from "./chartTemplate2";
import { round} from "mathjs";


const scatterDEDIFunc = (divRef, data0) => {
    const scatterDEDI = dc.scatterPlot(divRef);
    const scatterDimension = data0.dimension(d => d.Topic);
    const scatterGroup = scatterDimension.group().reduce(
        function(p, v) {
            p.DE2030 = (p.DE2030 || 0.) + v.DE2030;
            p.Disruptive = (p.Disruptive || 0.) + v.Disruptive;
            p.bewertet = (p.bewertet || 0.) + v.bewertet;
            return p;},
        function(p, v) {
            p.DE2030 = (p.DE2030 || 0.) - v.DE2030;
            p.Disruptive = (p.Disruptive || 0.) - v.Disruptive;
            p.bewertet = (p.bewertet || 0.) - v.bewertet;
            return p;},
        function() {return {DE2030:0., Disruptive:0., bewertet:0.};}
        );
    let range;

    scatterDEDI
        .height(275)
        .margins({top:5,right:15,bottom:30,left:25})
        .transitionDuration(1500)
        .dimension(scatterDimension)
        .group(scatterGroup)
        .renderHorizontalGridLines(true)
        .renderVerticalGridLines(true)
        .clipPadding(50)
        .symbolSize(8)
        .excludedColor('gray')
        // .brushOn(false)
        .renderLabel(false)
        .excludedSize(8)
        .excludedOpacity(0.2)
        .filterPrinter(function(filters){
            range = 'From ['+ round(filters[0][0][0],0)+','
                + round(filters[0][0][1],0) +'] to ['
                + round(filters[0][1][0],0) +','
                + round(filters[0][1][1],0) +']';
            return range; })
        .colorAccessor(d => d.value.bewertet)
        .colors(scaleLinear().domain([17,167]).range(["rgb(251,204,6)", "rgb(255,5,149)"]))
        .keyAccessor(function(d){return d.value.DE2030;})
        .valueAccessor(function(d){return d.value.Disruptive;})
        .y(scaleLinear().domain([3.4,5.4]))
        .x(scaleLinear().domain([2.2,5.4]))
        .yAxisLabel("Impact Invironment")
        .xAxisLabel("Impact DE2030")
        .title(function(d) {return [d.key,
            'Zahl der Bewertungen: ' + d.value.bewertet ,].join('\n');})
        .symbol(symbolDiamond);


    return scatterDEDI
};

export const ScatterDEDI = props => (
    <ChartTemplate2 chartFunction={scatterDEDIFunc} />
);