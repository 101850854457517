import React from 'react';
import './ProjectPage.css';
import broschure from "../data/5795 Foresight Fraunhofer_EN_br.pdf";
import phasa2short from "../data/phase2short.pdf";
import phasa2img from "../images/phase2b.png";
import bericht from "../data/Abschlussbericht _V2.pdf";
import broschureimg from "../images/broschureImage.png";

const BackgroundPage = () => (
    <div className="container-fluid">
        <h1>Fraunhofer Foresight Project</h1><hr/>
        <div className="px-3 py-3">
            <h3>Phase 1: Future topics with relevance to application-oriented research</h3>
            <p>51 topics of high relevance to applied research in 2030 were identified.</p>
            <section className="px-5">
                <div className="mypolaroid">
                    <a className="mya2" href={broschure} download>
                        <img src={broschureimg} alt="Broschure" className="image"/>
                        <div className="imgcontainer">
                            <p>Broschure als pdf runterladen</p>
                        </div>
                    </a>
                </div>
            </section>
        </div><hr/>


        <div className="px-3 py-3">
            <h3>Phase 2: Scanning Revised</h3>
            <p>Textmining von strukturierten und unstrukturierten Daten</p>
            <a href={phasa2short} download>
                <img src={phasa2img} alt="Phase 2" width="100%"/>
            </a>
            <a className="mya" href={bericht} download>Bericht als pdf runterladen</a>
        </div>

    </div>

);

export default BackgroundPage;